<template>
    <div class="builder-block" :class="[spacerValue(), backgroundColor()]">
        <div class="splitSection">
       
            <img 
                :src="block.fields['split__image'][0].value.temporary_url"
                :alt="block.fields['split__image'][0].value.alt_tag"
                class="splitSection__image"
            />

            <div class="splitSection__content grid-x align-self-middle" data-aos="fade-up">
                <h2>{{ block.fields['split__title'][0].value }}</h2>
                <div class="grid-x grid-margin-x xlarge-up-2">
                    <div class="cell"  v-html="block.fields['split__col1'][0].value" />
                    <div class="cell"  v-html="block.fields['split__col2'][0].value" />
                </div>

            </div>
        </div>
    </div>
</template>


<script>

    export default {
        name: "SplitFeaturedPanel",
        props: {
            block: {
                type: Object,
                required: true
            }
        },
        methods: {
            spacerValue() {
                var spacer = this.block.fields['split__spacer'][0].value || '';
                if(spacer === 'xsmall') return 'mb-18'
                if(spacer === 'small') return 'mb-32'
                if(spacer === 'medium') return 'mb-54'
                if(spacer === 'large') return 'mb-80'
                if(spacer === 'xlarge') return 'mb-120'
            },
            backgroundColor() {
                if(this.block.fields['split__style'][0].value === 'black') return 'bg-black white-text'
                if(this.block.fields['split__style'][0].value === 'sand') return 'bg-sand'
            }
        }
    }
</script>
